import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../styles/ServicesSection.css";
import GRCDevelopImg from "../assets/image2.webp";
import MobileDevelopImg from "../assets/image3.webp";
import CloudServiceImg from "../assets/image4.webp";
import VAPTImg from "../assets/image6.webp";
import RedTeamImg from "../assets/image7.webp";
const services = [
  {
    title: "Expertise in Governance, Risk, and Compliance",
    description:
      "Navigate the complexities of today's regulatory landscape with our tailored GRC services designed for your organization.",
    image:GRCDevelopImg
  },
  {
    title: "Innovative Mobile Applications",
    description:
      "We develop cutting-edge mobile applications to enhance efficiency and user experience in your industry.",
    image: MobileDevelopImg
  },
  {
    title: "Secure Cloud Solutions",
    description:
      "Our cloud services are designed with security in mind, ensuring your data and assets are protected while driving operational excellence.",
    image: CloudServiceImg
  },
  {
    title: "Proactive Security Solutions",
    description:
      "Identify and mitigate security risks with our Vulnerability Assessment and Penetration Testing services, securing your web, mobile, and network platforms.",
    image: VAPTImg
  },

  {
    title: "Red Team Assessment",
    description:
      "Our Red Teaming services put you in control amid simulated attack scenarios, preparing your organization to defend effectively.",
    image: RedTeamImg
  },
];

const ServicesSection = () => {
  return (
    <section id="services" className="services-section">
      <Container>
        <h2 className="text-center fw-bold mb-4">
          Unlock the potential of your business with specialized digital solutions
        </h2>
        <Row>
          {services.map((service, index) => (
            <Col lg={4} md={6} sm={12} key={index} className="mb-4 d-flex justify-content-center">
              <Card className="h-100 shadow-sm border-0 p-3">
                <Card.Img variant="top" src={service.image} className="service-img" />
                <Card.Body>
                  <Card.Title className="fw-bold">{service.title}</Card.Title>
                  <Card.Text className="text-muted">{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServicesSection;