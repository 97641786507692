import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../styles/home.css';

function MyNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#f8f9fa' }}>
      <Container>
        <Navbar.Brand href="/"><span style={{ color: '#3b82f6' }}><h2>AlgoToCode Tech</h2></span></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav className="align-items-center" activeKey="/home">
            <Nav.Link  href="/#home-page" className="text-dark" activeClassName="text-white bg-dark">Home</Nav.Link>
            <Nav.Link  href="/#services" className="text-dark" activeClassName="text-white bg-dark">Services</Nav.Link>
            <Nav.Link href="/#about-us" className="text-dark" activeClassName="text-white bg-dark">About Us</Nav.Link>
            <Nav.Link eventKey="/careers" href="/careers" className="text-dark" activeClassName="text-white bg-dark">Careers</Nav.Link>
            <Nav.Link  href="/#contact-us"> 
              <Button variant="outline-primary" className="my-0">Contact Us</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;