import React from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";
import TechSolutionImg from "../assets/image1.webp";

const IntroSection = () => {
  return (
    <section id='home-page' className="hero-section">
      <Container>
        <Row className="align-items-center">
          {/* Left Content */}
          <Col lg={6} md={12} className="text-center text-lg-start">
            <h1 className="fw-bold display-5">
              Elevate Your Digital Presence with <br />
              <span className="text-primary">Tailored Solutions</span>
            </h1>
            <p className="lead text-muted">
              We specialize in Governance, Risk, and Compliance (GRC) services, empowering businesses to navigate complexity with confidence.
            </p>
            <Button href='#contact-us' variant="primary" size="lg" className="mt-3">
              Connect With Us
            </Button>
          </Col>

          {/* Right Image */}
          <Col lg={6} md={12} className="text-center mt-4 mt-lg-0">
            <img
              src={TechSolutionImg}
              alt="Tech Solutions"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
      </section>
  )
}

export default IntroSection