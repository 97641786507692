import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CheckSquareFill } from "react-bootstrap-icons";
import "../styles/GrcSolutions.css";
import GrcSolutionImg from "../assets/image5.webp";

const GrcSolutions = () => {
    const services = [
        {
          title: "Custom GRC Services",
          description:
            "Our expertise enables you to establish compliance effortlessly while focusing on driving business growth.",
        },
        {
          title: "Innovative Mobile Solutions",
          description:
            "Transform your operations with our customized mobile applications, designed to meet the unique needs of your industry.",
        },
        {
          title: "Comprehensive Cloud Services",
          description:
            "Leverage our secure cloud solutions to enhance operational efficiency while ensuring data integrity and compliance.",
        },
        {
            title: "Proactive VAPT Services",
            description:
              "Identify and mitigate security risks across web, mobile, and network platforms effortlessly.",
        },
        {
            title: "Advanced Red Teaming",
            description:
              "Simulate real-world attacks to uncover vulnerabilities and fortify your defenses before they can be exploited.",
        },
      ];
  return (
    <section className="grc-section">
      <Container>
        <Row className="align-items-center">
          {/* Left Side - Content */}
          <Col lg={6} md={12} className="text-lg-start text-center">
            <h2 className="fw-bold">Elevate Your Business with GRC Solutions</h2>
            <p className="text-muted">
              Enhance your organization's capabilities with bespoke digital solutions
              tailored to your specific GRC challenges, ensuring seamless navigation 
              through complex regulatory landscapes.
            </p>

            {/* Service List */}
            {services.map((service, index) => (
                <div key={index} className="grc-service">
                <CheckSquareFill className="icon" />
                <div>
                    <h5>{service.title}</h5>
                    <p className="text-muted">
                    {service.description}
                    </p>
                </div>
                </div>

            ))}
          </Col>

          {/* Right Side - Image */}
          <Col lg={6} md={12} className="text-center">
            <img
              src={GrcSolutionImg}
              alt="GRC Solutions"
              className="img-fluid grc-img"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GrcSolutions;
