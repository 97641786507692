import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';
import CareerPage from './pages/CareerPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import NotFoundPage from './pages/NotFoundPage'; // Import your NotFoundPage component

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/careers" element={<CareerPage />} />
        {/* <Route path="*" element={<NotFoundPage />} /> Catch-all route for handling unknown routes */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;