import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../styles/HeroSection.css"
import HeroSectionImg from "../assets/hero-section.webp";
const HeroSection = () => {
  return (
    <section className="hero-section">
      <Container>
        <Row className="align-items-center">
          
          <Col lg={6} md={12} className="text-center">
            <img
              src={HeroSectionImg}
              alt="Digital Solutions"
              className="img-fluid hero-img"
            />
          </Col>

          
          <Col lg={6} md={12}>
            <h2 className="fw-bold">
              Transform Your Organization <br /> with Digital Solutions
            </h2>
            <p className="text-muted">
              Algo2Code Tech Pvt Ltd specializes in Governance, Risk, and
              Compliance (GRC) services, providing tailored solutions that align
              with your operational needs and enhance your compliance posture.
            </p>
            <p className="text-muted">
              Our skilled team designs intuitive mobile applications and secure
              cloud services that uphold industry standards while driving your
              business agility.
            </p>
            <Button href="#services" variant="primary" className="btn-lg">
              Discover Our Services
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
