import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import "../styles/AboutUs.css";

const aboutData = [
  { id: 1, title: "Our Vision", text: "To empower businesses to achieve their full potential through innovative digital solutions, driving growth, efficiency, and success." },
  { id: 2, title: "Our Mission", text: "To deliver exceptional value to our clients by providing expert guidance, tailored solutions, and unparalleled service, fostering long-term partnerships and mutual success." },
  { id: 3, title: "Our Core Values", text: "We are guided by a set of core values that prioritize integrity, innovation, collaboration, and customer-centricity, ensuring that our actions and decisions align with the highest standards of professionalism and ethics." },
  { id: 4, title: "Our Expertise", text: "With expertise in cutting-edge digital technologies, we design, develop, and deliver tailored solutions that address the unique challenges and opportunities of businesses, driving transformation, growth, and competitiveness." }
];

const AboutUs = () => {
  return (
    <Container id="about-us" className="py-5">
      <h2 className="text-primary text-center mb-4">About Us</h2>
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="pyramid-container">
            {aboutData.map((item, index) => (
              <motion.div
                key={item.id}
                className="pyramid-section"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.3 }}
              >
                <div className="pyramid-number">{item.id}</div>
                <div className="pyramid-content">
                  <h5 className="fw-bold">{item.title}</h5>
                  <p className="text-muted">{item.text}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
