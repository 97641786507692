import React from "react";
import MyNavbar from "../components/MyNavbar";
import "../styles/home.css"
import ServicesSection from "../components/ServicesSection";
import HeroSection from "../components/HeroSection";
import IntroSection from "../components/IntroSection";
import GrcSolutions from "../components/GrcSolutions";
import TeamSection from "../components/TeamSection";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";


const HomePage = () => {
  return (
    <div>
      <MyNavbar />
      <IntroSection />
      <HeroSection />
      <ServicesSection />
      <GrcSolutions />
      <TeamSection />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default HomePage;
