import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TelephoneFill, EnvelopeFill, GeoAltFill } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <footer  className="bg-light pt-5 pb-4">
      <Container id="contact-us">
        <Row className="text-center text-md-start">
          {/* Company Info */}
          <Col md={4}>
            <h5 className="text-primary fw-bold">Algo2Code Tech Pvt Ltd</h5>
            <p className="text-muted">
            Our specialized GRC services ensure your organization operates securely and efficiently in the evolving digital landscape, safeguarding your assets while you focus on your business growth.
            </p>
          </Col>

          {/* Our Services */}
          <Col md={2}>
            <h6 className="fw-bold">Our Services</h6>
            <ul className="list-unstyled text-muted">
              <li>GRC Services</li>
              <li>Mobile App Development</li>
              <li>Website & Cloud Services</li>
              <li>VAPT services</li>
            </ul>
          </Col>

          {/* About Us */}
          <Col md={2}>
            <h6 className="fw-bold">About Us</h6>
            <ul className="list-unstyled text-muted">
              <li>Vision</li>
              <li>Mission</li>
              <li>Core Values</li>
              <li>Expertise</li>
            </ul>
          </Col>

          {/* Contact Information */}
          <Col md={4}>
            <h6 className="fw-bold">Contact Information</h6>
            <ul className="list-unstyled text-muted">
              <li>
                <GeoAltFill className="text-primary me-2" />
                34/5b-24/20 Near Ganapuram Complex, New Pacha Peth Solapur 413006
              </li>
              <li>
                <TelephoneFill className="text-primary me-2" />
                +91 9309113761
              </li>
              <li>
                <EnvelopeFill className="text-primary me-2" />
                infos@algo2code.com
              </li>
            </ul>
          </Col>
        </Row>

        {/* Bottom Bar */}
        <hr />
        <div className="text-center text-muted">
          © 2025 Algo2Code Tech Pvt Ltd. All Rights Reserved.
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
