import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Briefcase } from "react-bootstrap-icons";
import Footer from "../components/Footer";
import MyNavbar from "../components/MyNavbar";

const CareerPage = () => {
  return (
    <div>
        <MyNavbar />
        <div style={{height: "80px"}}></div>
    <Container id="career" className="py-5 text-center">
      <h2 className="fw-bold">Join Our Team</h2>
      <p className="text-muted">We are always looking for talented individuals to grow with us.</p>

      <Row className="justify-content-center mt-4">
        <Col md={6}>
          <Card className="shadow-sm border-0 p-4">
            <Card.Body>
              <Briefcase size={50} className="text-primary mb-3" />
              <h4 className="fw-semibold">No Current Openings</h4>
              <p className="text-muted">
                We currently don’t have any open positions. Stay connected for future opportunities.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer />
    </div>
  );
};

export default CareerPage;