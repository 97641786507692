import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../styles/TeamSection.css";
import Person1 from "../assets/person1.jpg";
import Person2 from "../assets/person2.jpg";
import Person3 from "../assets/person3.jpg";
import Person4 from "../assets/person4.jpg";

const teamMembers = [
  { name: "Rohan Jain", role: "GRC Specialist", img: Person1 },
  { name: "Karan Singh", role: "Cloud Solutions Architect", img: Person2 },
  { name: "Nalini Gupta", role: "Technical Analyst", img: Person3 },
  { name: "Vivek Kumar", role: "Security Specialist", img: Person4},
];

const TeamSection = () => {
  return (
    <section className="team-section">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <h2 className="fw-bold">Discover Our Expertise</h2>
            <p className="text-muted">
              Experience seamless digital transformation with Algo2Code Tech Pvt Ltd. 
              Our proficient team excels in Governance, Risk, and Compliance (GRC) services, 
              equipping businesses to confidently navigate regulatory challenges.
            </p>
          </Col>
        </Row>
        
        <Row className="justify-content-center">
          {teamMembers.map((member, index) => (
            <Col key={index} lg={3} md={4} sm={6} xs={12} className="mb-4">
              <Card className="team-card">
                <Card.Img variant="top" src={member.img} className="rounded-circle team-img" />
                <Card.Body className="text-center">
                  <Card.Title className="fw-bold">{member.name}</Card.Title>
                  <Card.Text className="text-muted">{member.role}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default TeamSection;
